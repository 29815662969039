module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://af836861f1f34b17a73a34fec7f46ef9@sentry.io/1880587","ignoreErrors":["ResizeObserver loop limit exceeded","fb_xd_fragment"],"ignoreUrls":[{},{},{},{},{}],"environment":"prod","enabled":false},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHCS52B","includeInDevelopment":true,"gtmAuth":"muhxVMrB8NFJ4qXuZB8T0A","gtmPreview":"env-2","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-20649335-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"debug":false},
    },{
      plugin: require('../../../libs/gatsby-plugin-common/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
